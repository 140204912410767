import { useState, type FC, useEffect } from 'react'

import { useGetSponsors } from '../../api/hooks/sponsor'
import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import type { BookOfBusinessSearch } from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
  StatusFilter,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { filterSponsorsBenefits } from '../../utils/benefit'
import { getSponsorStatusQuery } from '../../utils/getSponsorStatusQuery'
import { DownloadSponsorsType } from '../../utils/sponsors'

import { useEmptyText } from './useEmptyText'

/**
 * The ActiveSponsors page component.
 */
export const ActiveSponsors: FC = () => {
  const auth = useAuth()
  const [search, setSearch] = useState<BookOfBusinessSearch>({ query: '', status: StatusFilter.All })
  const debouncedSearch = useDebounced(search, 500)
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false
  const enabled = auth.user?.permissions.isInternalUser === false && statusFilterFeatureToggle.isFetched
  const sponsorStatusQuery = getSponsorStatusQuery(debouncedSearch.status, StatusFilter.Active, isStatusFilterEnabled)
  const bookOfBusiness = useGetSponsors(
    {
      query: {
        q: debouncedSearch.query,
        ...sponsorStatusQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )

  const emptyText = useEmptyText('allClients', { query: debouncedSearch.query, status: debouncedSearch.status })
  const sponsors = bookOfBusiness.data?.bookOfBusiness?.sponsors ?? []

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchChange() {
      if (debouncedSearch.query || debouncedSearch.status) onPageChange(1)
    },
    [debouncedSearch, onPageChange],
  )

  // Tracking effects.

  useEffect(() => {
    if (debouncedSearch.query || debouncedSearch.status) {
      analytics.track('book of business table:search active employers', {
        query: debouncedSearch.query,
        ...(isStatusFilterEnabled && { status: debouncedSearch.status }),
      })
    }
  }, [debouncedSearch, isStatusFilterEnabled])

  useEffect(() => {
    if (debouncedSearch.query.length > 0 && sponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty active employers query`,
      })
  }, [debouncedSearch, sponsors.length])

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download active employers',
    })
  }

  return (
    <>
      <QueryResultUnconditional query={statusFilterFeatureToggle}>
        {(data) => {
          const isStatusFilterEnabled = data?.enabled ?? false
          return (
            <BookOfBusinessActionBar
              tab={BookOfBusinessTabKey.allSponsors}
              downloadButtonProps={{
                disabled: sponsors.length == 0,
                text: isStatusFilterEnabled ? 'Download all clients' : 'Download active clients',
                query: {
                  type: isStatusFilterEnabled ? DownloadSponsorsType.ALL : DownloadSponsorsType.ACTIVE,
                  q: debouncedSearch.query,
                  ...sponsorStatusQuery,
                  ...sort,
                },
                onClick: handleDownloadClick,
              }}
              label="Search in clients..."
              search={search}
              onChange={(search) => setSearch(search)}
            />
          )
        }}
      </QueryResultUnconditional>
      <QueryResultUnconditional query={bookOfBusiness}>
        {(data) => {
          const sponsors = filterSponsorsBenefits(data?.bookOfBusiness?.sponsors ?? [])

          return (
            <BookOfBusinessTable
              type="all"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </>
  )
}
