import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { StatusFilter } from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import type { BookOfBusinessTabKeyType } from '../BookOfBusiness'

const EMPTY_TEXT =
  'There are no clients that match your search. Please check the spelling of the client name or the accuracy of the policy number.'

/**
 * Returns an appropriate empty text to use on Book of Business pages.
 */
export const useEmptyText = (tab: BookOfBusinessTabKeyType, options: UseEmptyTextOptions): string => {
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false

  let noEmptyText: string = ''
  switch (tab) {
    case 'allClients':
      if (isStatusFilterEnabled)
        switch (options.status) {
          case StatusFilter.All:
            noEmptyText =
              'You currently have no clients. Your clients will appear here after they are onboarded with Sun Life.'
            break
          case StatusFilter.Active:
            noEmptyText =
              'You currently have no active clients. Your clients will appear here after they are onboarded with Sun Life.'
            break
          case StatusFilter.Terminated:
            noEmptyText =
              'There are no clients here yet. When a client has terminated all coverages with Sun Life, it will appear here.'
        }
      else
        noEmptyText =
          'You currently have no active clients. Your clients will appear here after they are onboarded with Sun Life.'
      break
    case 'recentlyViewed':
      if (isStatusFilterEnabled)
        switch (options.status) {
          case StatusFilter.All:
            noEmptyText =
              "You haven't viewed any clients in the past 30 days. When you view a client, it will appear here."
            break
          case StatusFilter.Active:
            noEmptyText =
              "You haven't viewed any active clients in the past 30 days. When you view a client, it will appear here."
            break
          case StatusFilter.Terminated:
            noEmptyText =
              "You haven't viewed any terminated clients in the past 30 days. When you view a client, it will appear here."
            break
        }
      else
        noEmptyText = "You haven't viewed any clients in the past 30 days. When you view a client, it will appear here."
      break
    case 'starred':
      if (isStatusFilterEnabled)
        switch (options.status) {
          case StatusFilter.All:
            noEmptyText =
              'There are no clients here yet. When you click the star next to a client, it will appear here.'
            break
          case StatusFilter.Active:
            noEmptyText =
              'There are no active clients here yet. When you click the star next to a client, it will appear here.'
            break
          case StatusFilter.Terminated:
            noEmptyText =
              'There are no terminated clients here yet. When you click the star next to a client, it will appear here.'
            break
        }
      else noEmptyText = 'There are no clients here yet. When you click the star next to a client, it will appear here.'
      break
    case 'terminated':
      noEmptyText =
        'There are no clients here yet. When a client has terminated all coverages with Sun Life, it will appear here.'
  }

  return options.query ? EMPTY_TEXT : noEmptyText
}

type UseEmptyTextOptions = {
  query: string
  status?: StatusFilter // TODO: Remove optional when removing `BEX_STATUS_FILTER` feature toggle.
}
