import { useRecentlyViewedSponsorStore } from './store/useRecentlyViewedSponsorStore'
import { useRecentlyViewedSponsors } from './useRecentlyViewedSponsors'

/**
 * Returns the count of recently viewed sponsors.
 */
export function useRecentlyViewedSponsorsCount() {
  const [entries] = useRecentlyViewedSponsorStore()
  const recentlyViewedSponsors = useRecentlyViewedSponsors(undefined, { enabled: entries.length > 0 })
  return entries.length === 0 ? 0 : recentlyViewedSponsors.data?.bookOfBusiness?.totalRecords ?? entries.length
}
