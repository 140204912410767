import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { useGetFavoritedSponsors } from '../../api/hooks/sponsor'
import { useGetFavoriteSponsorIds } from '../../api/hooks/useGetFavoriteSponsorIds'
import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import type { BookOfBusinessSearch } from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
  StatusFilter,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { filterSponsorsBenefits } from '../../utils/benefit'
import { getSponsorStatusQuery } from '../../utils/getSponsorStatusQuery'
import { DownloadSponsorsType } from '../../utils/sponsors'

import { useEmptyText } from './useEmptyText'

export const StarredSponsors: FC = () => {
  const auth = useAuth()
  const [search, setSearch] = useState<BookOfBusinessSearch>({ query: '', status: StatusFilter.All })
  const debouncedSearch = useDebounced(search, 500)
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false
  const enabled = auth.user?.permissions.isInternalUser === false
  const favoriteIds = useGetFavoriteSponsorIds({ params: { brokerId: auth.user?.brokerID ?? '' } })
  const sponsorStatusQuery = getSponsorStatusQuery(debouncedSearch.status, StatusFilter.Active, isStatusFilterEnabled)
  const starredSponsors = useGetFavoritedSponsors(
    {
      query: {
        q: debouncedSearch.query,
        ...sponsorStatusQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )

  const emptyText = useEmptyText('starred', { query: debouncedSearch.query, status: debouncedSearch.status })

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchChange() {
      if (debouncedSearch.query || debouncedSearch.status) onPageChange(1)
    },
    [debouncedSearch, onPageChange],
  )

  // Tracking effects.

  useEffect(() => {
    if (debouncedSearch.query || debouncedSearch.status) {
      analytics.track('book of business table:search starred employers', {
        query: debouncedSearch.query,
        ...(isStatusFilterEnabled && { status: debouncedSearch.status }),
      })
    }
  }, [debouncedSearch, isStatusFilterEnabled])

  useEffect(() => {
    if (debouncedSearch.query.length > 0 && starredSponsors.data?.bookOfBusiness?.totalRecords === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty starred employers query`,
      })
  }, [debouncedSearch, starredSponsors.data?.bookOfBusiness?.totalRecords])

  const handleSearchChange = (search: BookOfBusinessSearch) => {
    setSearch(search)
  }

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download starred employers',
    })
  }

  return (
    <>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.starred}
        downloadButtonProps={{
          disabled: starredSponsors.data?.bookOfBusiness?.totalRecords == 0,
          text: 'Download starred clients',
          query: {
            type: DownloadSponsorsType.STARRED,
            q: debouncedSearch.query,
            ...sponsorStatusQuery,
            ...sort,
            favorite: 'true',
          },
          onClick: handleDownloadClick,
        }}
        label="Search in starred..."
        search={search}
        onChange={handleSearchChange}
      />
      <QueryResultUnconditional query={starredSponsors}>
        {(data) => {
          const sponsors = filterSponsorsBenefits(data?.bookOfBusiness?.sponsors ?? [])
            // Filter sponsor by current value in favorite store (this store use optimistic update).
            .filter((sponsor) => favoriteIds.data?.sponsorIds.includes(sponsor.sponsorId))

          return (
            <BookOfBusinessTable
              type="starred"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </>
  )
}
