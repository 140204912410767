import { type UseQueryResult } from '@tanstack/react-query'

import type { UseGetRecentlyViewedSponsorsQuery, UseGetSponsorOptions } from '../api/hooks/sponsor'
import { useGetRecentlyViewedSponsors } from '../api/hooks/sponsor'

import { useRecentlyViewedSponsorStore } from './store/useRecentlyViewedSponsorStore'
import { useAuth } from './useAuth'

/**
 * Represents the query parameters for the recently viewed sponsors.
 * There are some properties that are not required for this query:
 * - `sponsorIds` - Because sponsor ids are retrieved from the recently viewed sponsor store.
 * - `sponsorStatus` - Because the query is not limited to active or terminated sponsors.
 * - `favorite` - Because the query is not limited to favorited sponsors.
 */
type UseRecentlyViewedSponsorsQuery = Omit<
  UseGetRecentlyViewedSponsorsQuery,
  'sponsorIds' | 'sponsorStatus' | 'favorite'
>

/**
 * Custom hook that retrieves recently viewed sponsors based on the provided query.
 *
 * @param query - The query object containing parameters for retrieving recently viewed sponsors.
 */
export const useRecentlyViewedSponsors = (
  params: { query: UseRecentlyViewedSponsorsQuery } = { query: {} },
  options: Pick<UseGetSponsorOptions, 'prefetchNextPage' | 'enabled'> = {},
): UseQueryResult<Sponsor_ListResponse> => {
  const auth = useAuth()
  const [entries] = useRecentlyViewedSponsorStore()
  const enabled = Boolean(options.enabled && auth.user?.permissions.isInternalUser === false && entries.length > 0)
  return useGetRecentlyViewedSponsors(
    {
      query: {
        ...params.query,
        sponsorIds: entries.map((entry) => entry.sponsorId).join(','),
      },
    },
    { enabled, prefetchNextPage: options.prefetchNextPage },
  )
}
