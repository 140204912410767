import { Link } from '@component-library/helios'
import { type FC } from 'react'

import { useDownloadSponsors } from '../../api/hooks/useDownloadSponsors'
import { useAuth } from '../../hooks/useAuth'
import type { DownloadSponsorsType } from '../../utils/sponsors'

export type BookOfBusinessDownloadButtonProps = {
  text: string
  disabled: boolean
  query: Omit<Sponsor_DownloadRequestQueryParams, 'brokerID' | 'payToNumbers' | 'username' | 'type'> & {
    type: DownloadSponsorsType
  }
  onClick?: () => void
}

export const BookOfBusinessDownloadButton: FC<BookOfBusinessDownloadButtonProps> = ({
  text,
  disabled,
  query,
  onClick,
}: BookOfBusinessDownloadButtonProps) => {
  const { user } = useAuth()
  const download = useDownloadSponsors()
  const handleDownloadClick: React.MouseEventHandler = (e) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    onClick?.()
    download({
      query: {
        ...query,
        brokerID: user?.brokerID,
        payToNumbers: JSON.stringify(user?.payToNumbers),
        username: user?.username,
      },
    })
  }

  return (
    <Link href="#" onClick={handleDownloadClick} disabled={disabled}>
      {text}
    </Link>
  )
}
