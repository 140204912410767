import { DateTime } from 'luxon'

import { BenefitStatus } from './benefit'

/**
 * Parse a policy commission that can be a empty string. In that case or if the parsing fails return 0.
 */
export const parseComissions = (commissions: Policy['commissionsPaidYearToDate']) => {
  return parseFloat(commissions) || 0
}

/**
 * Return the commissions text for user display.
 */
export const getCommissionsText = (commissions: number) =>
  `$${commissions} Commissions paid as of the first of January this year`

/**
 * Filters coverages in each policy by (effectiveDate > Today) and coverage status.
 * Will also filter policies out that have had all their coverages filtered out.
 * @param policies the policies to be filtered
 * @returns the filtered policies
 */
export function filterCoveragesInPolicies(policies: Policy[]): Policy[] {
  const filteredPolicies: Policy[] = []

  const checkCovStatus = (cov: Coverage) =>
    [BenefitStatus.RISK_APPROVED, BenefitStatus.TERMINATED].includes(cov.status as BenefitStatus)
  const checkCovEffectiveDate = (cov: Coverage) =>
    DateTime.fromMillis(new Date(cov.effectiveDate).getTime()) <= DateTime.now()

  policies.forEach((pol) => {
    // check if each covarge has effective date in the past and if status is correct
    const filteredCoverages = pol.coverages.filter((cov) => checkCovStatus(cov) && checkCovEffectiveDate(cov))

    // if all coverages have been filtered out filter out policy too
    if (filteredCoverages.length) filteredPolicies.push({ ...pol, coverages: filteredCoverages })
  })
  return filteredPolicies
}

export const getDentalPolicyFromPolicies = (policies: Policy[]): string | undefined => {
  const dentalPolicy = policies.reduce<string | undefined>((pol, policy) => {
    policy.coverages.map((coverage) => {
      if (
        ['basdental', 'dentalppo', 'dentalprepd', 'sldental', 'voldental'].includes(
          coverage.benefitType.toLowerCase(),
        ) &&
        coverage.status.toUpperCase() !== 'TERMINATED'
      ) {
        pol = policy.policyNumber
      }
    })
    return policy.policyStatus.toUpperCase() === 'ISSUED' ? pol : undefined
  }, undefined)

  return dentalPolicy
}

/**
 * @see https://maxwellhealth.atlassian.net/browse/PC-2888
 */
export const filterPolicyValidForSunLifeConnect = (policies: Policy[]): Policy[] => {
  return policies.filter((policy) => {
    const isRelevantStatus = ['COMMITMENT_PENDING', 'NEW', 'ISSUE_READY', 'ISSUED', 'TERMINATED'].includes(
      policy.policyStatus.toUpperCase(),
    )

    // protobuf.Timestamp which policy.policyStatus is derived from is in seconds, so doing calculation in seconds
    const nowInSeconds = Math.round(Date.now() / 1000)
    const ninetyDaysInSeconds = 90 * 24 * 60 * 60
    const statusDateStartIsMoreThan90DaysInThePast = nowInSeconds - policy.statusDateStart > ninetyDaysInSeconds
    const isTerminatedNintetyPlusDaysAgo =
      policy.policyStatus.toUpperCase() === 'TERMINATED' && statusDateStartIsMoreThan90DaysInThePast

    return isRelevantStatus && !isTerminatedNintetyPlusDaysAgo
  })
}
