import { Body, Link } from '@component-library/helios'
import { useQueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { useRecentlyViewedSponsorStore } from '../../hooks/store/useRecentlyViewedSponsorStore'
import type { SponsorSortField } from '../../hooks/useBookOfBusinessPagination'
import { trackEvent } from '../../utils/analytics'
import { formatDateFullMonthWithYear } from '../../utils/format'
import { filterPolicyValidForSunLifeConnect, getDentalPolicyFromPolicies } from '../../utils/policy'
import type { SponsorId } from '../../utils/recentlyViewedSponsor'
import { SponsorStatus, getSponsorStatusText } from '../../utils/sponsors'
import type { PaginatedTableProps } from '../PaginatedTable/PaginatedTable'
import { PaginatedTable } from '../PaginatedTable/PaginatedTable'
import { SponsorActionsMenu } from '../SponsorActionsMenu/SponsorActionsMenu'

import styles from './BookOfBusinessTable.module.css'
import { StarIcon } from './StarIcon'

export type TableType = 'all' | 'recentlyViewed' | 'starred' | 'terminated'

const sponsorSortFieldMap: Record<string, SponsorSortField> = {
  sponsorName: 'name',
  sponsor_name: 'name',
  renewalDate: 'date_next_renew',
  next_renewal_date: 'date_next_renew',
  terminatedDate: 'date_next_renew',
}

type BookOfBusinessTableProps = {
  type: TableType
  sponsors: Sponsor[] | undefined
  emptyText?: ReactNode
  onSortChange?: (field: SponsorSortField, direction: SortDirection) => void
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  totalItems: number
  page: string
  pageSize: string
}

export const BookOfBusinessTable = ({
  type,
  sponsors,
  emptyText,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  totalItems,
  page,
  pageSize,
}: BookOfBusinessTableProps) => {
  const queryClient = useQueryClient()
  const dateColumn = useDateColumn(type)
  const columns = useBookOfBusinessTableColumns(type)
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false

  const handleStarClick = (_id: string, starred: boolean) => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:favorite icon:status=${starred ? 'checked' : 'unchecked'}`,
    })

    if (type === 'starred') {
      queryClient.invalidateQueries({ queryKey: ['sponsor', 'favorited'] })
    } else {
      queryClient.removeQueries({ queryKey: ['sponsor', 'favorited'] })
    }
  }

  const handleSortChange = (field: string, direction: SortDirection) => {
    onSortChange?.(sponsorSortFieldMap[field], direction)
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:sort:category=${field}:sort order=${direction}`,
    })
  }

  const handlePageChange: typeof onPageChange = (page) => {
    onPageChange?.(page)
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  const initialPagination = {
    page: Number(page),
    pageSize: Number(pageSize),
  }

  return (
    <div>
      <PaginatedTable
        table={{
          columns,
          rows:
            sponsors?.map((sponsor) => ({
              sponsorName: (
                <div className={styles.clientName}>
                  <Link as={ReactRouterDomLink} to={`/sponsor/${sponsor.sponsorId}`} state={{ type }} size="sm">
                    {sponsor.sponsorName}
                  </Link>
                  <br />
                  <Body variant="small" color="caption">
                    Policy {sponsor.policies.map((policy) => `#${policy.policyNumber}`).join(', ')}
                  </Body>
                </div>
              ),
              benefits: sponsor.benefits?.join(', '),
              ...(dateColumn ? { [dateColumn.key]: dateColumn.render(sponsor) } : {}),
              ...(isStatusFilterEnabled ? { status: getSponsorStatusText(sponsor.sponsorStatus) } : {}),
              actions: (
                // TODO: Each row/cell should has a key prop, this should be handled from the underlying component.
                // A key is needed here, otherwise that cell doesn't change on table sort.
                <div className={styles.actionCell} key={`sponsor-${sponsor.sponsorId}-actions`}>
                  <div>
                    <StarIcon
                      className={styles.star}
                      sponsorId={sponsor.sponsorId}
                      onToggle={(star) => handleStarClick(sponsor.sponsorId.toString(), star)}
                    />
                  </div>
                  {/* Refactor this component to accept `sponsor` instead. */}
                  <SponsorActionsMenu
                    type="icon"
                    sponsorId={sponsor.sponsorId}
                    showViewSponsor={true}
                    menuItemClickTrackTitle="book of business table:button:three dot action menu"
                    benefits={sponsor.benefits}
                    hasStopLoss={sponsor.policies.map((policy) => policy.hasStopLoss).includes(true)}
                    hasHealthNavigator={sponsor.policies.map((policy) => policy.hasHealthNavigator).includes(true)}
                    hasDentalBenefit={sponsor.policies.some((policy) => policy.hasDental)}
                    dentalPolicy={getDentalPolicyFromPolicies(sponsor.policies)}
                    policyNumbers={filterPolicyValidForSunLifeConnect(sponsor.policies).map(
                      (policy) => policy.policyNumber,
                    )}
                    activeSponsor={sponsor.policies.some(
                      (policy) => policy.policyStatus.toUpperCase() !== 'TERMINATED',
                    )}
                  />
                </div>
              ),
            })) ?? [],
        }}
        emptyText={emptyText}
        totalItems={totalItems}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        initialPagination={initialPagination}
      />
    </div>
  )
}

function useBookOfBusinessTableColumns(type: TableType): PaginatedTableProps['table']['columns'] {
  const dateColumn = useDateColumn(type)
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false
  const columns: PaginatedTableProps['table']['columns'] = [
    {
      field: 'sponsorName',
      title: 'Client name',
      align: 'left',
      sortable: true,
    },
    {
      field: 'benefits',
      title: 'Benefits',
      align: 'left',
    },
  ]

  if (dateColumn) {
    columns.push({
      field: dateColumn.key,
      title: dateColumn.title,
      align: 'left',
      sortable: type !== 'recentlyViewed',
    })
  }

  if (isStatusFilterEnabled) {
    columns.push({
      field: 'status',
      title: 'Status',
      align: 'left',
    })
  }

  columns.push({
    field: 'actions',
    title: 'Actions',
    align: 'left',
    width: '1px',
  })

  return columns
}

function useDateColumn(type: TableType) {
  const [recentlyViewedSponsorEntries] = useRecentlyViewedSponsorStore()

  function getViewedAt(sponsorId: SponsorId) {
    return recentlyViewedSponsorEntries.find((entry) => entry.sponsorId === sponsorId)?.viewedAt
  }

  switch (type) {
    case 'all':
    case 'starred':
      return {
        key: 'renewalDate',
        title: 'Next renewal',
        render: (sponsor: Sponsor) =>
          sponsor.sponsorStatus !== SponsorStatus.TERMINATED && sponsor.nextRenewalDate
            ? formatDateFullMonthWithYear(new Date(sponsor.nextRenewalDate))
            : '',
      }
    case 'recentlyViewed':
      return {
        key: 'lastViewed',
        title: 'Last viewed',
        render: (sponsor: Sponsor) => formatDateFullMonthWithYear(new Date(getViewedAt(sponsor.sponsorId) ?? 0)),
      }
    case 'terminated':
      return null
  }
}
