import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import type { BookOfBusinessSearch } from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
  StatusFilter,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { useRecentlyViewedSponsorStore } from '../../hooks/store/useRecentlyViewedSponsorStore'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { useRecentlyViewedSponsors } from '../../hooks/useRecentlyViewedSponsors'
import { trackEvent } from '../../utils/analytics'
import { filterSponsorsBenefits } from '../../utils/benefit'
import { formatDate } from '../../utils/format'
import { getSponsorStatusQuery } from '../../utils/getSponsorStatusQuery'
import { DownloadSponsorsType } from '../../utils/sponsors'

import { useEmptyText } from './useEmptyText'

/**
 * The RecentlyViewedSponsors page component.
 */
export const RecentlyViewedSponsors: FC = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false
  const [search, setSearch] = useState<BookOfBusinessSearch>({ query: '', status: StatusFilter.All })
  const debouncedSearch = useDebounced(search, 500)
  const [recentlyViewedSponsorEntries] = useRecentlyViewedSponsorStore()
  const sponsorStatusQuery = getSponsorStatusQuery(debouncedSearch.status, StatusFilter.All, isStatusFilterEnabled)
  const query = useRecentlyViewedSponsors(
    {
      query: {
        q: debouncedSearch.query,
        ...sponsorStatusQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled: statusFilterFeatureToggle.isFetched, prefetchNextPage: true },
  )
  const emptyText = useEmptyText('recentlyViewed', { query: debouncedSearch.query, status: debouncedSearch.status })

  const handleSearchChange = (search: BookOfBusinessSearch) => {
    setSearch(search)
  }

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchChange() {
      if (debouncedSearch.query || debouncedSearch.status) onPageChange(1)
    },
    [debouncedSearch, onPageChange],
  )

  // Tracking effects.

  useEffect(() => {
    if (debouncedSearch.query || debouncedSearch.status) {
      analytics.track('book of business table:search recently viewed employers', {
        query: debouncedSearch.query,
        ...(isStatusFilterEnabled && { status: debouncedSearch.status }),
      })
    }
  }, [debouncedSearch, isStatusFilterEnabled])

  useEffect(() => {
    const recentlyViewedSponsors = query.data?.bookOfBusiness?.sponsors ?? []

    if (debouncedSearch.query.length > 0 && recentlyViewedSponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty recently viewed employers query`,
      })
  }, [debouncedSearch, query.data?.bookOfBusiness?.sponsors])

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download recently viewed employers',
    })
  }

  const recentlyViewedSponsors = query.data?.bookOfBusiness?.sponsors ?? []

  return (
    <>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.recentlyViewed}
        downloadButtonProps={{
          disabled: recentlyViewedSponsors.length === 0,
          text: 'Download recently viewed clients',
          query: {
            type: DownloadSponsorsType.RECENTLY_VIEWED,
            q: debouncedSearch.query,
            ...sponsorStatusQuery,
            ...sort,
            sponsorIds: recentlyViewedSponsorEntries.map(({ sponsorId }) => sponsorId).join(','),
            recentlyViewedDates: recentlyViewedSponsorEntries
              .map(({ viewedAt }) => formatDate(new Date(viewedAt)))
              .join(','),
          },
          onClick: handleDownloadClick,
        }}
        label="Search in recently viewed..."
        search={search}
        onChange={handleSearchChange}
      />
      <QueryResultUnconditional query={query}>
        {(data) => {
          const sponsors = filterSponsorsBenefits(data?.bookOfBusiness?.sponsors ?? [])
          const sponsorsTotal = data?.bookOfBusiness?.totalRecords ?? 0

          return (
            <BookOfBusinessTable
              type="recentlyViewed"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={sponsorsTotal}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </>
  )
}
