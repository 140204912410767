.bookOfBusinessActionBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  gap: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.searchContainer {
  flex: 1 0 auto;
}

.downloadContainer {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}

.statusFilterContainer {
  flex: 0 0 calc(100% / 6);
  display: flex;
  align-items: center;
}

.statusFilter {
  width: 100%;
}
