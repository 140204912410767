import { StatusFilter } from '../components/BookOfBusinessActionBar/BookOfBusinessActionBar'

export function getSponsorStatusQuery(
  status: StatusFilter | undefined,
  defaults: StatusFilter,
  isStatusFilterEnabled: boolean,
) {
  if (isStatusFilterEnabled) {
    // Not sending any status value allows to retrieve all sponsors.
    return status === StatusFilter.All ? {} : { sponsorStatus: status }
  }
  return defaults === StatusFilter.All ? {} : { sponsorStatus: defaults }
}
