import { Tabs } from '@component-library/helios'
import { useRef, type FC, type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetFeatureToggleByName } from '../api/hooks/useGetFeatureToggleByName'
import { HelpContent } from '../components/HelpContent/HelpContent'
import { PageContent } from '../components/PageContent/PageContent'
import { PageTitle } from '../components/PageTitle/PageTitle'
import { ResponsiveContainer } from '../components/ResponsiveContainer/ResponsiveContainer'
import { useActiveSponsorCount } from '../hooks/useActiveSponsorCount'
import { useAutoScrollTop } from '../hooks/useAutoScrollTop'
import { useDefaultTabNavigation } from '../hooks/useDefaultTabNavigation'
import { useFavoriteSponsorCount } from '../hooks/useFavoriteSponsorCount'
import { useRecentlyViewedSponsorsCount } from '../hooks/useRecentlyViewedSponsorsCount'
import { useSponsorCount } from '../hooks/useSponsorCount'
import { useTerminatedSponsorCount } from '../hooks/useTerminatedSponsorCount'

import { ActiveSponsors } from './BookOfBusiness/ActiveSponsors'
import { RecentlyViewedSponsors } from './BookOfBusiness/RecentlyViewedSponsors'
import { StarredSponsors } from './BookOfBusiness/StarredSponsors'
import { TerminatedSponsors } from './BookOfBusiness/TerminatedSponsors'
import styles from './BookOfBusiness.module.css'

export enum BookOfBusinessTabKey {
  AllClients = 'allClients',
  RecentlyViewed = 'recentlyViewed',
  Starred = 'starred',
  Terminated = 'terminated',
}

export type BookOfBusinessTabKeyType = `${BookOfBusinessTabKey}`

type BookOfBusinessTabValue = {
  pathname: string
  title: string
  render: () => ReactNode
}

const bookOfBusinessTabs: Record<BookOfBusinessTabKey, BookOfBusinessTabValue> = {
  [BookOfBusinessTabKey.AllClients]: {
    pathname: '/all-clients',
    title: 'Active clients',
    render: () => <ActiveSponsors />,
  },
  [BookOfBusinessTabKey.RecentlyViewed]: {
    pathname: '/recently-viewed',
    title: 'Recently viewed',
    render: () => <RecentlyViewedSponsors />,
  },
  [BookOfBusinessTabKey.Starred]: { pathname: '/starred', title: 'Starred', render: () => <StarredSponsors /> },
  [BookOfBusinessTabKey.Terminated]: {
    pathname: '/terminated',
    title: 'Terminated',
    render: () => <TerminatedSponsors />,
  },
} as const

const defaultTabPathname = bookOfBusinessTabs.allClients.pathname
const bookOfBusinessTabPathnames = Object.values(bookOfBusinessTabs).map((tab) => tab.pathname)

type BookOfBusinessProps = {
  tab?: BookOfBusinessTabKey
}

export const BookOfBusiness: FC<BookOfBusinessProps> = ({ tab = BookOfBusinessTabKey.AllClients }) => {
  const helpRef = useRef<HTMLDivElement | null>(null)
  const helpClientHeight = helpRef.current?.clientHeight ?? 0
  const contentMinHeight = `calc(100vh - ${helpClientHeight + 80}px)` // 80px is the header height

  useDefaultTabNavigation(bookOfBusinessTabPathnames, defaultTabPathname)

  useAutoScrollTop()

  const navigate = useNavigate()

  const tabs = useBookOfBusinessTabs()

  return (
    <ResponsiveContainer>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper} style={{ minHeight: contentMinHeight }}>
          <PageContent>
            <PageTitle>Book of business</PageTitle>
            <nav>
              <Tabs
                id="bookOfBusinessTabs"
                defaultActiveKey={bookOfBusinessTabs[tab].pathname}
                onSelect={(key) => {
                  if (key) {
                    navigate(key)
                  }
                }}
                items={tabs.map((tab) => ({
                  title: `${tab.title} ${sponsorCountTabCopy(tab.count ?? 0)}`,
                  eventKey: tab.pathname,
                  content: null, // Intentional, contents will be render outside on navigation.
                }))}
              />
            </nav>
            <main>
              <PageContent>{bookOfBusinessTabs[tab].render()}</PageContent>
            </main>
          </PageContent>
        </div>
        <div ref={helpRef} className={styles.helpWrapper}>
          <PageContent>
            <HelpContent />
            <hr className={styles.removeMargins} />
          </PageContent>
        </div>
      </div>
    </ResponsiveContainer>
  )
}

const sponsorCountTabCopy = (count: number): string => (count > 0 ? `(${count})` : '')

export const getBookOfBusinessTabByPathname = (pathname: string) => {
  return Object.values(bookOfBusinessTabs).find((tab) => tab.pathname === pathname)
}

export const getBookOfBusinessTabKeyByPathname = (pathname: string) => {
  for (const key of Object.values(BookOfBusinessTabKey)) {
    if (key in bookOfBusinessTabs && bookOfBusinessTabs[key].pathname === pathname) return key
  }
}

/**
 * Returns an array of Book of Business tabs with the count of sponsors for each tab.
 */
function useBookOfBusinessTabs(): Array<{
  title: string
  count: number
  pathname: string
  render: () => ReactNode
}> {
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false

  const starredSponsorsCount = useFavoriteSponsorCount() ?? 0
  const sponsorCount = useSponsorCount({ enabled: isStatusFilterEnabled }) ?? 0
  const activeSponsorCount = useActiveSponsorCount({ enabled: !isStatusFilterEnabled }) ?? 0
  const recentlyViewedSponsor = useRecentlyViewedSponsorsCount()
  const terminatedSponsorCount = useTerminatedSponsorCount() ?? 0

  if (isStatusFilterEnabled) {
    return [
      { ...bookOfBusinessTabs.allClients, title: 'All clients', count: sponsorCount },
      { ...bookOfBusinessTabs.recentlyViewed, count: recentlyViewedSponsor },
      { ...bookOfBusinessTabs.starred, count: starredSponsorsCount },
    ]
  } else {
    return [
      { ...bookOfBusinessTabs.allClients, count: activeSponsorCount },
      { ...bookOfBusinessTabs.recentlyViewed, count: recentlyViewedSponsor },
      { ...bookOfBusinessTabs.starred, count: starredSponsorsCount },
      { ...bookOfBusinessTabs.terminated, count: terminatedSponsorCount },
    ]
  }
}
