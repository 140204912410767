export function getAllSponsorPoliciesTerminated90DaysAgo(sponsor: Sponsor) {
  return (
    sponsor.policies.length > 0 &&
    sponsor.policies.every((policy) => {
      const terminated = policy.policyStatus.toLowerCase() === 'terminated'
      if (!terminated) return false
      const terminatedDaysAgo = (Date.now() / 1000 - policy.renewalDate) / 60 / 60 / 24
      return terminatedDaysAgo >= 90
    })
  )
}

export enum SponsorStatus {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export function getSponsorStatusText(sponsorStatus: string): string {
  switch (sponsorStatus) {
    case SponsorStatus.ACTIVE:
      return 'Active'
    case SponsorStatus.TERMINATED:
      return 'Terminated'
    default:
      return 'Unknown'
  }
}

export enum DownloadSponsorsType {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  RECENTLY_VIEWED = 'RECENTLY VIEWED',
  STARRED = 'STARRED',
  TERMINATED = 'TERMINATED',
}
