import { TextField, Select } from '@component-library/helios'
import type { FC } from 'react'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { trackEvent } from '../../utils/analytics'
import type { BookOfBusinessDownloadButtonProps } from '../BookOfBusinessDownloadButton/BookOfBusinessDownloadButton'
import { BookOfBusinessDownloadButton } from '../BookOfBusinessDownloadButton/BookOfBusinessDownloadButton'

import styles from './BookOfBusinessActionBar.module.css'

export enum BookOfBusinessTabKey {
  allSponsors = 'active employers',
  recentlyViewed = 'recently viewed',
  starred = 'starred',
  terminated = 'terminated',
}

export type BookOfBusinessSearch = {
  query: string
  status?: StatusFilter // TODO: Remove optional when `BEX_STATUS_FILTER` is enabled.
}

export type BookOfBusinessActionBarProps = {
  downloadButtonProps: BookOfBusinessDownloadButtonProps
  label: string
  tab: BookOfBusinessTabKey
  search: BookOfBusinessSearch
  onChange: (search: BookOfBusinessSearch) => void
}

export const BookOfBusinessActionBar: FC<BookOfBusinessActionBarProps> = ({
  downloadButtonProps,
  label,
  tab,
  search,
  onChange,
}) => {
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false

  const handleSearchInputClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:search bar click ${tab}`,
    })
    analytics.track('book of business table:search bar click', {
      type: 'other',
      action: 'clk',
    })
  }

  return (
    <section className={styles.bookOfBusinessActionBar}>
      <form className={styles.searchContainer}>
        <TextField
          id="bookOfBusinessActionBar"
          data-testid="bookOfBusinessActionBar"
          label={label}
          value={search.query}
          icon="fal fa-search"
          iconPosition="after"
          onChange={(event) => {
            const query = event.target.value
            onChange({ ...search, query })
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
          onClick={() => handleSearchInputClick()}
        />
      </form>
      <div className={styles.downloadContainer}>
        <BookOfBusinessDownloadButton {...downloadButtonProps} />
      </div>
      <div className={styles.statusFilterContainer}>
        {isStatusFilterEnabled && (
          <StatusDropdown value={search.status} onChange={(status) => onChange({ ...search, status })} />
        )}
      </div>
    </section>
  )
}

// Status filter dropdown

type StatusDropdownProps = {
  value?: StatusFilter
  onChange: (status: StatusFilter) => void
}

function StatusDropdown(props: StatusDropdownProps) {
  return (
    <Select
      id="bookOfBusinessActionBarStatusFilter"
      className={styles.statusFilter}
      label="Status"
      placeholder="Choose a status"
      options={statusOptions}
      selected={props.value}
      onChange={(event) => props.onChange(statusOptions[event.index].value)}
    />
  )
}

export enum StatusFilter {
  All = 'all',
  Active = 'active',
  Terminated = 'terminated',
}

const statusOptions: Array<{ label: string; value: StatusFilter }> = [
  {
    label: 'All',
    value: StatusFilter.All,
  },
  {
    label: 'Active',
    value: StatusFilter.Active,
  },
  {
    label: 'Terminated',
    value: StatusFilter.Terminated,
  },
]
